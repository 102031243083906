import React, { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Projects from './Projects';
import Contact from './Contact';
import Gallery from './Gallery';
import '../styles/Home.css'; 
import bg_img from '../assets/images/bg_img.webp';
import personal_pic from '../assets/images/personal_pic.webp';
import { MdKeyboardDoubleArrowUp } from "react-icons/md";

const Home = () => {
  const [showButton, setShowButton] = useState(false);

  // Function to scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Define site title and links specific to the Home page
  const homePageLinks = [
    { text: 'HOME', to: '/' },
    { text: 'ABOUT', to: '/about' },
    { text: 'PROJECTS', to: '/all_projects' },
    { text: 'GALLERY', to: '#gallery' },
    { text: 'CONTACT', to: '#contact' }
  ];

  // Function to handle scroll event
  const handleScroll = () => {
    if (window.scrollY > 200) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  // Add scroll event listener
  window.addEventListener('scroll', handleScroll);

  return (
    <div>
      {/* Preload images */}
      <img src={bg_img} style={{ display: 'none' }} alt='preload' />

      <div className="header-container">
        {/* Reuse the Header component with specific site title and links */}
        <Header links={homePageLinks} />
      </div>
      
      <div className="background-image-container">
        <img src={bg_img} alt='background' className="background-image" />
        <div className="text-block">
          <h1>WELCOME</h1>
          <p>It is with great pleasure that I invite you to peruse the various facets of my work, 
            and I hope it provides insights into my dedication to delivering outstanding results. 
          </p>
        </div>
      </div>



      <div className='personal_statement_container'>
        <div className='personal_statement'>
          <h2>HELLO!</h2>
          <h3>I'M LUQMAAN FIHRI ABDALLAH</h3>
          <p>I'm a recent Masters graduate in architecture from Near East University in North Cyprus. 
            With a passion for design and a particular interest in rendering and 3D modelling, 
            I look forward to being a valuable member of your team.</p>

          <div className='about_me_link'>
            <a href='/about' className='about_me_text'>
              About Me
            </a>
          </div>

        </div>

        <div className='personal_pic_container'>
          <img src={personal_pic} alt='personal_picture' className="personal_pic" />
        </div>
      </div>

      <main>
        <section id="projects">
          <Projects />
        </section>

        <section id="gallery">
          <Gallery />
        </section>

        <section id="contact">
          <Contact />
        </section>

        {/* Back to Top button */}
        {showButton && (
          <button className="back-to-top" onClick={scrollToTop}>
           <MdKeyboardDoubleArrowUp className='arrow_top'/> Back to Top
          </button>
        )}
      </main>
      <Footer />
    </div>
  );
};

export default Home;
