import React, { useRef } from 'react';
import emailjs from "@emailjs/browser";
import '../styles/Contact.css';
import { MdLocationOn, MdEmail ,MdCall } from "react-icons/md";



const Contact = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
  
    // Access form fields
    const name = form.current.user_name.value.trim();
    const email = form.current.user_email.value.trim();
    const message = form.current.message.value.trim();
  
    // Validate fields
    if (!name || !email || !message) {
      alert('Please fill in all fields.');
      return;
    }
  
    // Validate email format
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      alert('Please enter a valid email address.');
      return;
    }

    
  
    // If all validations pass, send the email
    emailjs
      .sendForm(
        "service_o5j3l6d",
        "template_f3dsetl",
        form.current,
        "d28tn4dKb-CjBIwGT",
      )
      .then(
        () => {
          console.log('Message Sent');
          // Display message
          alert('Message sent successfully!');
          // Clear form fields
          form.current.reset();
        },
        (error) => {
          console.log('FAILED...', error.text);
          // Optionally display an error message
          alert('Failed to send message. Please try again later.');
        },
      );
  };
  

  return (
    <section className='contact_sec' id='contact_id'>
      <div className='contact_container'>
        <div className='left_contact_part'>
          <h1 className='h_contact'>Get In Touch</h1>

          <div className='contact_form'>
            <form ref={form} onSubmit={sendEmail}>
              <label>Enter Your Name*</label>
              <input className='contact_input' type="text" name="user_name" />
              <label>Enter Your Email*</label>
              <input className='contact_input' type="email" name="user_email" />
              <label>Message*</label>
              <textarea name="message" />
              <input className='send_button' type="submit" value="Send" />
            </form>
          </div>

          
        </div>

        <ul className='contact_details'>
          
          <li>
            <p className='p_contact'>Curious about our architectural expertise, design innovations, 
            or a potential collaboration? </p>
          </li>

          <li className='contact_li'>
            <MdLocationOn className='contact_icon'/>
            <div className="contact_info">
              <span>Al Khuwayr South <br></br>Muscat, Oman</span><br></br><br></br>
            </div>
          </li>
          <li className='contact_li'>
            <MdCall className='contact_icon'/>
            <div className="contact_info">
              <span>+968 9434 3038</span><br></br><br></br>
            </div>
          </li>
          <li className='contact_li'>
            <MdEmail className='contact_icon'/>
            <div className="contact_info">
              <span>luquefihri@gmail.com</span><br></br><br></br>
            </div>
          </li>

          <li>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8696.581709066824!2d58.37685693246386!3d23.587751521076427!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e91ffa8879aafc9%3A0xdb53876d0d79a72c!2sMuscat%2C%20Oman!5e0!3m2!1sen!2ske!4v1714233530409!5m2!1sen!2ske" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title='myFrame'></iframe>
          </li>
          
        </ul>
      </div>
      

      

      
    </section>
  );
};

export default Contact;