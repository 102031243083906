import React from 'react';
import '../styles/Gallery.css';
import SliderWithThumbnails from './SliderWithThumbnails';
import '../styles/SliderWithThumbnails.css'; 



// Abyan Towers Images (Interior)//
import gallery1 from '../assets/images/gallery_webp/gallery1.webp';
import gallery2 from '../assets/images/gallery_webp/gallery2.webp';
import gallery3 from '../assets/images/gallery_webp/gallery3.webp';
import gallery4 from '../assets/images/gallery_webp/gallery4.webp';
import gallery5 from '../assets/images/gallery_webp/gallery5.webp';
import gallery6 from '../assets/images/gallery_webp/gallery6.webp';
import gallery7 from '../assets/images/gallery_webp/gallery7.webp';
import gallery8 from '../assets/images/gallery_webp/gallery8.webp';
import gallery9 from '../assets/images/gallery_webp/gallery9.webp';
import gallery10 from '../assets/images/gallery_webp/gallery10.webp';
import gallery11 from '../assets/images/gallery_webp/gallery11.webp';
import gallery12 from '../assets/images/gallery_webp/gallery12.webp';
import gallery13 from '../assets/images/gallery_webp/gallery13.webp';
import gallery14 from '../assets/images/gallery_webp/gallery14.webp';
import gallery15 from '../assets/images/gallery_webp/gallery15.webp';
import gallery16 from '../assets/images/gallery_webp/gallery16.webp';
import gallery17 from '../assets/images/gallery_webp/gallery17.webp';


const Gallery = () => {

  const images = [
    gallery1,
    gallery2,
    gallery3,
    gallery4,
    gallery5,
    gallery6,
    gallery7,
    gallery8,
    gallery9,
    gallery10,
    gallery11,
    gallery12,
    gallery13,
    gallery14,
    gallery15,
    gallery16,
    gallery17
  ];



  return (
    <section className='gallery_sec' id='gallery_id'>
      <div className='gallery_intro'>
          <h1>View Gallery</h1>
          <p>There may be no better way to communicate what we do than through images. 
             As you browse the site, please take a few moments to view some of my previous work.</p>
      </div>

      <SliderWithThumbnails images={images} className='slider_container_one' />
        
    </section>
  );
};

export default Gallery;
