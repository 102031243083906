import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import SliderWithThumbnails from '../SliderWithThumbnails';
import '../../styles/ProjectOne.css';

import imgE1 from '../../assets/images/project_five/imgE1.webp';
import imgE2 from '../../assets/images/project_five/imgE2.webp';
import imgE3 from '../../assets/images/project_five/imgE3.webp';
import imgE4 from '../../assets/images/project_five/imgE4.webp';
import imgE5 from '../../assets/images/project_five/imgE5.webp';
import imgE6 from '../../assets/images/project_five/imgE6.webp';
import imgE7 from '../../assets/images/project_five/imgE7.webp';
import imgE8 from '../../assets/images/project_five/imgE8.webp';
import imgE9 from '../../assets/images/project_five/imgE9.webp';
import imgE10 from '../../assets/images/project_five/imgE10.webp';
import imgE11 from '../../assets/images/project_five/imgE11.webp';
import imgE12 from '../../assets/images/project_five/imgE12.webp';
import imgE13 from '../../assets/images/project_five/imgE13.webp';
import imgE14 from '../../assets/images/project_five/imgE14.webp';


const ProjectFive = () => {

  // Define site title and links specific to the AllProjects page
  const Project_5_PageLinks = [
    { text: 'HOME', to: '/' },
    { text: 'ABOUT', to: '/about' },
    { text: 'PROJECTS', to: '/all_projects' }, 
    { text: 'GALLERY', to: '/#gallery_id' },
    { text: 'CONTACT', to: '/#contact_id' }
  ];

  const images = [
    imgE1,
    imgE2,
    imgE3,
    imgE4,
    imgE5,
    imgE6,
    imgE7,
    imgE8,
    imgE9,
    imgE10,
    imgE11,
    imgE12,
    imgE13,
    imgE14
  ];


  return (
    <section className='projectone_sec'>
      {/* Reuse the Header component with specific site title and links */}
      <Header links={Project_5_PageLinks} />
      
      <SliderWithThumbnails images={images} className='slider_container_one' />

      <div className='project_description_container'>
        <div className='project_description_title'>
          <h2>MASJID KICHULA</h2>
          <h3>Lamu, Kenya</h3>
          <p> 10 / 01 / 2022</p>
          <a href='/all_projects' className='view_project_text'>
              {'<'}Back To Projects 
          </a>
        </div>

        <div className='project_description_p'>
          <p>The restoration of Masjid Kichula presented a unique 
            architectural challenge due to the limited space available 
            in a small site, located in a congested area surrounded by
             residential buildings and a graveyard. The mosque is 
             located in the historic town of Lamu, Kenya.<br></br><br></br>
             The objective was to maximize the usable space within the existing 
             structure without expanding the site. To achieve this, a two-storey 
             design was proposed, utilizing robust structural framing. 
             This innovative solution eliminated the need for pillars in 
             the prayer hall, thus maximizing the available space for worshippers.<br></br><br></br>
             Despite the site's limitations, the project successfully improved
              the mosque's capacity and functionality while preserving its 
              spiritual and community significance.</p>
        </div>
      </div>

      <Footer />
    </section>
  );
};

export default ProjectFive;
