import React from 'react';
import '../styles/Projects.css';
import imgA1 from '../assets/images/project_one/imgA1.webp';
import imgB1 from '../assets/images/project_two/imgB1.webp';
import imgC1 from '../assets/images/project_three/imgC1.webp';



const Projects = () => {

  return (
    <section className='projects_sec'>
      
      <div className='projects_container'>
        <h2 className='h_projects'>FEATURED PROJECTS</h2>


        <div className='projects_sample'>
          <div className='projects_sample_img'>
            <a href='/project_one' className='view_project_text'>
              <img className='project_image' src={imgA1} alt='sample project'/>
            </a>
          </div>

          <div className='project_sample_block'>
            <h2>09/23</h2>
            <h3>SUNSET FITNESS CENTER</h3>
            <p>The project involved the renovation of a pre-existing sports building 
              and the restoration of a pool into an indoor pool building at a beachfront 
              location in Alsancak, North Cyprus. The primary objective was to create an 
              indoor pool and a cafeteria building, while also ensuring that the site 
              remained a usable waterfront.</p>

            <a href='/project_one' className='view_project_text'>
              View Project {'>'}
            </a>
          </div>

        </div>

        <div className='projects_sample'>
          <div className='projects_sample_img'>
            <a href='/project_two' className='view_project_text'>
              <img className='project_image' src={imgB1} alt='sample project'/>
            </a>
          </div>

          <div className='project_sample_block'>
            <h2>04/23</h2>
            <h3>SPORTS VILLAGE/ MASTERS THESIS</h3>
            <p>The thesis proposes a sports village design in Lapta, North Cyprus, 
              within a mixed-use residential complex. The design aims to be accessible, 
              promote well-being, foster human connection, and blend with the natural environment. 
              The design prioritizes environmental preservation, sustainable principles, 
              and a variety of residential and recreational areas. The goal is to create 
              an inclusive, diverse sports village that enhances community well-being and 
              social cohesion.</p>

            <a href='/project_two' className='view_project_text'>
              View Project {'>'}
            </a>
          </div>

        </div>

        <div className='projects_sample'>
          <div className='projects_sample_img'>
            <a href='/project_three' className='view_project_text'>
              <img className='project_image' src={imgC1} alt='sample project'/>
            </a>
          </div>

          <div className='project_sample_block'>
            <h2>11/22</h2>
            <h3> MASJID UMMU KULTHUM HILAL</h3>
            <p>The mosque is located in Lamu County, Kenya. The construction of the 
              project was completed in November 2022. I was tasked with designing the 
              entrance portal of the mosque, including the dome. The dome,
               an integral part of the mosque’s architecture, was carefully 
               designed to reflect the mosque’s spiritual significance and the 
               community’s cultural heritage. The design process was meticulous, 
               ensuring that the dome not only enhanced the aesthetic appeal of 
               the mosque but also provided a welcoming and serene atmosphere for worshippers.</p>

            <a href='/project_three' className='view_project_text'>
              View Project {'>'}
            </a>
          </div>

        </div>

        <div className='explore_text_container'>
          <a href='/all_projects' className='explore_text'>
             View All Projects
          </a>
        </div>

                       
      </div>


      
    
    </section>
  );
};

export default Projects;
