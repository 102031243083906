import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Projects from './pages/Projects';
import Contact from './pages/Contact';
import AllProjects from './pages/AllProjects';
import Gallery from './pages/Gallery';
import ProjectOne from './pages/projects_folder/ProjectOne';
import ProjectTwo from './pages/projects_folder/ProjectTwo';
import ProjectThree from './pages/projects_folder/ProjectThree';
import ProjectFour from './pages/projects_folder/ProjectFour';
import ProjectFive from './pages/projects_folder/ProjectFive';
import ProjectSix from './pages/projects_folder/ProjectSix';


const App = () => {
  return (

    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/all_projects" element={<AllProjects />} />
        <Route path="/project_one" element={<ProjectOne />} />
        <Route path="/project_two" element={<ProjectTwo />} />
        <Route path="/project_three" element={<ProjectThree />} />
        <Route path="/project_four" element={<ProjectFour />} />
        <Route path="/project_five" element={<ProjectFive />} />
        <Route path="/project_six" element={<ProjectSix />} />


        {/* Add more routes for other pages */}
      </Routes>
    </Router>
  );
};

export default App;
