import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../styles/SliderWithThumbnails.css';

const SliderWithThumbnails = ({ images }) => {
  const sliderRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => {
      setCurrentIndex(next);
    }
  };

  const scrollToImage = (index) => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index);
    }
  };

  return (
    <div className='slider_section'>
      <div>
        <Slider ref={sliderRef} {...settings}>
          {images.map((image, index) => (
            <div key={index} className='slider_container'>
              <img src={image} alt={`Slide ${index}`} />
            </div>
          ))}
        </Slider>
      </div>
      <div className='thumbnail_container'>
        {images.map((image, index) => (
          <div
            key={index}
            style={{ margin: '0 5px', cursor: 'pointer' }}
            onClick={() => scrollToImage(index)}
            className={`thumbnail ${currentIndex === index ? 'active-thumbnail' : ''}`}
          >
            <img src={image} alt={`Thumbnail ${index}`} style={{ width: '100px', height: '4rem' }} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SliderWithThumbnails;
