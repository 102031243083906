import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import '../styles/Header.css';
import { RxHamburgerMenu } from 'react-icons/rx';
import { MdClose } from 'react-icons/md';

const Header = ({ links, contactSectionId }) => {
  const [isActive, setIsActive] = useState(false);

  // Function to show the side navigation
  const shownav_side = () => {
    const nav_side = document.querySelector('.nav_side ul');
    nav_side.style.display = 'flex';
  };

  // Function to hide the side navigation
  const hidenav_side = () => {
    const nav_side = document.querySelector('.nav_side ul');
    nav_side.style.display = 'none';
  };


  return (
    <header>
      {/* Navigation for small screens */}
      <nav className={`nav_side ${isActive ? 'active' : ''}`}>
        <ul>
          <button type="button" className="close" onClick={hidenav_side}>
            <MdClose />
          </button>
          {links.map((link, index) => (
            <li key={index}>
              {link.text === 'CONTACT' || link.text === 'GALLERY' ? (
                <a
                  href={link.text === 'CONTACT' ? '/#contact_id' : '/#gallery_id'}
                  onClick={hidenav_side}
                >
                  {link.text}
                </a>
              ) : (
                <NavLink
                  to={link.to}
                  activeClassName="active"
                  onClick={(e) => {
                    setIsActive(false); // Close the side navigation after clicking on a link
                  }}
                >
                  {link.text}
                </NavLink>
              )}

            </li>
          ))}
        </ul>
      </nav>

      {/* Navigation for larger screens */}
      <nav className="nav">
        <Link className="site-title" to="/">
          <span className="logo_text">Luqmaan Fihri Abdallah</span>
        </Link>
        <ul>
          {links.map((link, index) => (
            <li key={index}>
              {link.text === 'CONTACT' || link.text === 'GALLERY' ? (
                <a
                  href={link.text === 'CONTACT' ? '/#contact_id' : '/#gallery_id'}
                  onClick={(e) => {
                    setIsActive(false); // Close the side navigation after clicking on a link
                  }}
                >
                  {link.text}
                </a>
              ) : (
                <NavLink
                  to={link.to}
                  activeClassName="active"
                  onClick={(e) => {
                    setIsActive(false); // Close the side navigation after clicking on a link
                  }}
                >
                  {link.text}
                </NavLink>
              )}

            </li>
          ))}
        </ul>

        <button type="button" className="ham_menu" onClick={shownav_side}>
          <RxHamburgerMenu />
        </button>
      </nav>
    </header>
  );
};

export default Header;

