import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import SliderWithThumbnails from '../SliderWithThumbnails';
import '../../styles/ProjectOne.css';
import imgA1 from '../../assets/images/project_one/imgA1.webp';
import imgA2 from '../../assets/images/project_one/imgA2.webp';
import imgA3 from '../../assets/images/project_one/imgA3.webp';
import imgA4 from '../../assets/images/project_one/imgA4.webp';
import imgA5 from '../../assets/images/project_one/imgA5.webp';
import imgA6 from '../../assets/images/project_one/imgA6.webp';
import imgA7 from '../../assets/images/project_one/imgA7.webp';
import imgA8 from '../../assets/images/project_one/imgA8.webp';
import imgA9 from '../../assets/images/project_one/imgA9.webp';
import imgA10 from '../../assets/images/project_one/imgA10.webp';


const ProjectSix = () => {

  // Define site title and links specific to the AllProjects page
  const Project_6_PageLinks = [
    { text: 'HOME', to: '/' },
    { text: 'ABOUT', to: '/about' },
    { text: 'PROJECTS', to: '/all_projects' }, 
    { text: 'GALLERY', to: '/#gallery_id' },
    { text: 'CONTACT', to: '/#contact_id' }
  ];

  const images = [
    imgA1,
    imgA2,
    imgA3,
    imgA4,
    imgA5,
    imgA6,
    imgA7,
    imgA8,
    imgA9,
    imgA10
  ];


  return (
    <section className='projectone_sec'>
      {/* Reuse the Header component with specific site title and links */}
      <Header links={Project_6_PageLinks} />
      
      <SliderWithThumbnails images={images} className='slider_container_one' />

      <div className='project_description_container'>
        <div className='project_description_title'>
          <h2>BOMBOLULU BEDSITTERS</h2>
          <h3>Mombasa, Kenya</h3>
          <p> 05 / 12 / 2021</p>
          <a href='/all_projects' className='view_project_text'>
              {'<'}Back To Projects 
          </a>
        </div>

        <div className='project_description_p'>
          <p>The project involved designing a single-floor apartment 
            of bedsitters on a 40 x 80 ft site. The client's requirement
             was to accommodate as many bedsitters as possible within 
             the given space.<br></br><br></br>
             My conceptual design successfully incorporated four bedsitters,
              each equipped with a kitchenette, a bathroom, and a partitioned
               private and public space. The partitions were made using 
               lightweight gypsum walls, an efficient solution that
                maximized the use of space while providing privacy.<br></br><br></br>
                This design not only met the client's demand for maximizing 
                the number of bedsitters but also ensured a comfortable and 
                functional living space for the residents.</p>
        </div>
      </div>

      <Footer />
    </section>
  );
};

export default ProjectSix;
