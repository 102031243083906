import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import SliderWithThumbnails from '../SliderWithThumbnails';
import '../../styles/ProjectOne.css';

import imgA1 from '../../assets/images/project_one/imgA1.webp';
import imgA2 from '../../assets/images/project_one/imgA2.webp';
import imgA3 from '../../assets/images/project_one/imgA3.webp';
import imgA4 from '../../assets/images/project_one/imgA4.webp';
import imgA5 from '../../assets/images/project_one/imgA5.webp';
import imgA6 from '../../assets/images/project_one/imgA6.webp';
import imgA7 from '../../assets/images/project_one/imgA7.webp';
import imgA8 from '../../assets/images/project_one/imgA8.webp';
import imgA9 from '../../assets/images/project_one/imgA9.webp';
import imgA10 from '../../assets/images/project_one/imgA10.webp';


const ProjectOne = () => {

  // Define site title and links specific to the AllProjects page
  const Project_1_PageLinks = [
    { text: 'HOME', to: '/' },
    { text: 'ABOUT', to: '/about' },
    { text: 'PROJECTS', to: '/all_projects' }, 
    { text: 'GALLERY', to: '/#gallery_id' },
    { text: 'CONTACT', to: '/#contact_id' }
  ];

  const images = [
    imgA1,
    imgA2,
    imgA3,
    imgA4,
    imgA5,
    imgA6,
    imgA7,
    imgA8,
    imgA9,
    imgA10
  ];


  return (
    <section className='projectone_sec'>
      {/* Reuse the Header component with specific site title and links */}
      <Header links={Project_1_PageLinks} />
      <SliderWithThumbnails images={images} className='slider_container_one' />

      <div className='project_description_container'>
        <div className='project_description_title'>
          <h2>SUNSET FITNESS CENTER</h2>
          <h3>Alsancak, North Cyprus</h3>
          <p> 11 / 09 / 2023</p>
          <a href='/all_projects' className='view_project_text'>
              {'<'}Back To Projects 
          </a>
          
        </div>

        <div className='project_description_p'>
          <p>The project involved the renovation of a pre-existing 
            sports building and the restoration of a pool into an 
            indoor pool building at a beachfront location in Alsancak.
            The primary objective was the restoration of a pool into 
            an indoor pool building and a cafeteria building at a 
            beachfront location in Alsancak, while also ensuring 
            that the site remained a usable waterfront. 
            The cafeteria building was strategically located 
            to serve both the sports center users and the beach 
            visitors.<br></br><br></br>
            I developed a comprehensive site plan that took into 
            consideration the existing structures, retaining walls,
            the beachfront, and the need for easy accessibility 
            and circulation within the site. I also created a 3D 
            model of the proposed design, as well as  high-quality 
            renders that showcased the design in its context, 
            highlighting key features such as the indoor pool 
            and the cafeteria building.<br></br><br></br>
            The pool building was designed with a foldable roof,
            allowing for an open-air experience during favorable 
            weather conditions. A wooden deck was also incorporated 
            at the beach to ensure easy accessibility and to provide 
            a space for relaxation and social interaction.<br></br><br></br>
            The project served as a unique opportunity to design 
            an indoor pool that not only fulfilled functional needs 
            but also elevated the user experience through its 
            integration with the beachfront.</p>
        </div>
      </div>

      <Footer />
    </section>
  );
};

export default ProjectOne;
