import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import SliderWithThumbnails from '../SliderWithThumbnails';
import '../../styles/ProjectOne.css';

import imgC1 from '../../assets/images/project_three/imgC1.webp';
import imgC2 from '../../assets/images/project_three/imgC2.webp';
import imgC3 from '../../assets/images/project_three/imgC3.webp';
import imgC4 from '../../assets/images/project_three/imgC4.webp';
import imgC5 from '../../assets/images/project_three/imgC5.webp';
import imgC6 from '../../assets/images/project_three/imgC6.webp';
import imgC7 from '../../assets/images/project_three/imgC7.webp';
import imgC8 from '../../assets/images/project_three/imgC8.webp';
import imgC9 from '../../assets/images/project_three/imgC9.webp';


const ProjectThree = () => {

  // Define site title and links specific to the AllProjects page
  const Project_3_PageLinks = [
    { text: 'HOME', to: '/' },
    { text: 'ABOUT', to: '/about' },
    { text: 'PROJECTS', to: '/all_projects' }, 
    { text: 'GALLERY', to: '/#gallery_id' },
    { text: 'CONTACT', to: '/#contact_id' }
  ];

  const images = [
    imgC1,
    imgC2,
    imgC3,
    imgC4,
    imgC5,
    imgC6,
    imgC7,
    imgC8,
    imgC9
  ];


  return (
    <section className='projectone_sec'>
      {/* Reuse the Header component with specific site title and links */}
      <Header links={Project_3_PageLinks} />
      <SliderWithThumbnails images={images} className='slider_container_one' />

      <div className='project_description_container'>
        <div className='project_description_title'>
          <h2>MASJID UMMU KULTHUM HILAL</h2>
          <h3>Lamu, Kenya</h3>
          <p> 04 / 11 / 2022</p>
          <a href='/all_projects' className='view_project_text'>
              {'<'}Back To Projects 
          </a>
        </div>

        <div className='project_description_p'>
          <p>The mosque is located in Lamu County, Kenya. 
            The construction of the project was completed 
            in November 2022. I was tasked with designing 
            the entrance portal of the mosque, including 
            the dome. The dome, an integral part of the mosque’s 
            architecture, was carefully designed to reflect 
            the mosque’s spiritual significance and the community’s 
            cultural heritage. The design process was meticulous, 
            ensuring that the dome not only enhanced the aesthetic 
            appeal of the mosque but also provided a welcoming 
            and serene atmosphere for worshippers.</p>
        </div>
      </div>

      <Footer />
    </section>
  );
};

export default ProjectThree;
