import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import SliderWithThumbnails from '../SliderWithThumbnails';
import '../../styles/ProjectOne.css';

import imgD1 from '../../assets/images/project_four/imgD1.webp';
import imgD2 from '../../assets/images/project_four/imgD2.webp';
import imgD3 from '../../assets/images/project_four/imgD3.webp';
import imgD4 from '../../assets/images/project_four/imgD4.webp';
import imgD5 from '../../assets/images/project_four/imgD5.webp';
import imgD6 from '../../assets/images/project_four/imgD6.webp';
import imgD7 from '../../assets/images/project_four/imgD7.webp';
import imgD8 from '../../assets/images/project_four/imgD8.webp';
import imgD9 from '../../assets/images/project_four/imgD9.webp';
import imgD10 from '../../assets/images/project_four/imgD10.webp';
import imgD11 from '../../assets/images/project_four/imgD11.webp';
import imgD12 from '../../assets/images/project_four/imgD12.webp';


const ProjectFour = () => {

  // Define site title and links specific to the AllProjects page
  const Project_4_PageLinks = [
    { text: 'HOME', to: '/' },
    { text: 'ABOUT', to: '/about' },
    { text: 'PROJECTS', to: '/all_projects' }, 
    { text: 'GALLERY', to: '/#gallery_id' },
    { text: 'CONTACT', to: '/#contact_id' }
  ];

  const images = [
    imgD1,
    imgD2,
    imgD3,
    imgD4,
    imgD5,
    imgD6,
    imgD7,
    imgD8,
    imgD9,
    imgD10,
    imgD11,
    imgD12
  ];


  return (
    <section className='projectone_sec'>
      {/* Reuse the Header component with specific site title and links */}
      <Header links={Project_4_PageLinks} />
      <SliderWithThumbnails images={images} className='slider_container_one' />

      <div className='project_description_container'>
        <div className='project_description_title'>
          <h2>MADRASSA (ISLAMIC SCHOOL)</h2>
          <h3>Mombasa, Kenya</h3>
          <p> 15 / 08 / 2022</p>
          <a href='/all_projects' className='view_project_text'>
              {'<'}Back To Projects 
          </a>
        </div>

        <div className='project_description_p'>
          <p>The Madrassa project is a three-storey Islamic school 
            designed with a deep understanding of its religious and 
            educational functions. The building comprises three 
            classrooms, a teachers' room, and an office, all 
            thoughtfully arranged to facilitate learning and 
            interaction.  The windows are fitted with a louvre system, 
            allowing for optimal control of sunlight and creating 
            a comfortable indoor climate.<br></br><br></br>
            In response to the client's requirement for a prayer-friendly
             environment, ablution areas were incorporated adjacent to 
             the washrooms, ensuring convenience and respect for Islamic 
             rituals. The second floor was designed as a semi-open space,
              equipped with shading elements to create a serene and 
              comfortable prayer area.<br></br><br></br>
              This design not only meets the functional needs of an 
              educational institution but also respects and enhances 
              the religious practices of its users.</p>
        </div>
      </div>

      <Footer />
    </section>
  );
};

export default ProjectFour;
