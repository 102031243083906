import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import SliderWithThumbnails from '../SliderWithThumbnails';
import '../../styles/ProjectOne.css';

import imgB1 from '../../assets/images/project_two/imgB1.webp';
import imgB2 from '../../assets/images/project_two/imgB2.webp';
import imgB3 from '../../assets/images/project_two/imgB3.webp';
import imgB4 from '../../assets/images/project_two/imgB4.webp';
import imgB5 from '../../assets/images/project_two/imgB5.webp';
import imgB6 from '../../assets/images/project_two/imgB6.webp';
import imgB7 from '../../assets/images/project_two/imgB7.webp';
import imgB8 from '../../assets/images/project_two/imgB8.webp';
import imgB9 from '../../assets/images/project_two/imgB9.webp';
import imgB10 from '../../assets/images/project_two/imgB10.webp';
import imgB11 from '../../assets/images/project_two/imgB11.webp';
import imgB12 from '../../assets/images/project_two/imgB12.webp';
import imgB13 from '../../assets/images/project_two/imgB13.webp';
import imgB14 from '../../assets/images/project_two/imgB14.webp';
import imgB15 from '../../assets/images/project_two/imgB15.webp';
import imgB16 from '../../assets/images/project_two/imgB16.webp';
import imgB17 from '../../assets/images/project_two/imgB17.webp';
import imgB18 from '../../assets/images/project_two/imgB18.webp';
import imgB19 from '../../assets/images/project_two/imgB19.webp';
import imgB20 from '../../assets/images/project_two/imgB20.webp';
import imgB21 from '../../assets/images/project_two/imgB21.webp';
import imgB22 from '../../assets/images/project_two/imgB22.webp';
import imgB23 from '../../assets/images/project_two/imgB23.webp';
import imgB24 from '../../assets/images/project_two/imgB24.webp';



const ProjectTwo = () => {

  // Define site title and links specific to the AllProjects page
  const Project_2_PageLinks = [
    { text: 'HOME', to: '/' },
    { text: 'ABOUT', to: '/about' },
    { text: 'PROJECTS', to: '/all_projects' }, 
    { text: 'GALLERY', to: '/#gallery_id' },
    { text: 'CONTACT', to: '/#contact_id' }
  ];

  const images = [
    imgB1,imgB2,imgB3,imgB4,imgB5,imgB6,imgB7,imgB8,imgB9,imgB10,imgB11,imgB12,
    imgB13,imgB14,imgB15,imgB16,imgB17,imgB18,imgB19,imgB20,imgB21,imgB22,imgB23,imgB24
  ];


  return (
    <section className='projectone_sec'>
      {/* Reuse the Header component with specific site title and links */}
      <Header links={Project_2_PageLinks} />
      
      <SliderWithThumbnails images={images} className='slider_container_one' />

      <div className='project_description_container'>
        <div className='project_description_title'>
          <h2>MASTERS THESIS PROJECT</h2>
          <h3>Near East University ,North Cyprus</h3>
          <p> 08 / 04 / 2023</p>
          <a href='/all_projects' className='view_project_text'>
              {'<'}Back To Projects 
          </a>
        </div>

        <div className='project_description_p'>
          <p>The basis of the thesis is on designing a sports village
             in a proposed mixed-use residential context in Lapta, 
             North Cyprus. The aim is to create a comprehensive design
              that is easily accessible, fosters human connection, 
              promotes physical well-being, and embraces the natural 
              environment.<br></br><br></br>
              The sports village design includes various buildings such 
              as a sports center, residential buildings, and a social 
              building for paintball storage and management offices. 
              The design prioritizes walkability, with cars confined 
              to the southern edge of the site and a network of paths 
              and walkways connecting all areas. Public spaces include 
              a tiered central park, a recreational path along the shore,
              and multifunctional outdoor sports courts.<br></br><br></br>
              The design ensures accessibility for all, with facilities 
              like a bus stop, multiple staircases and ramps, and a 
              harbor adjacent to the beach. Recreational activities 
              focus on sports, with a multifunctional sports center, 
              outdoor sports activities, and beach activities. 
              The design also includes a camping area and decks for 
              sunbathing and fishing.<br></br><br></br>
              The sports center, designed with environmental preservation 
              in mind, is partly submerged to integrate with the terrain 
              and minimize topographical alteration. The two-story sports building, 
              accessible from both floors due to terrain difference, is oriented 
              north-south to avoid direct sunlight for players. The modified 
              saw-tooth roof, divided into four unique segments with varying 
              slope angles, allows natural light in while minimizing glare. 
              The roof is also clad with artificial grass for aesthetic 
              integration with the surroundings.<br></br><br></br>
              The basketball arena, approximately 600 square meters
               with a ceiling height of 7 meters high, accommodates 
               storage, a health unit, and match officials seating 
               area under the audience seating. Changing rooms and 
               toilet facilities are available on both floors for 
               indoor and outdoor activities. The spacious lobby
                allows for crowd interaction without congestion.<br></br><br></br>
                The goal was to develop a harmonious and inviting sports 
                village that enhances inclusivity and offers a wide range 
                of sports and recreational activities, contributing to 
                the well-being and social cohesion of the community.</p>
        </div>
      </div>

      <Footer />
    </section>
  );
};

export default ProjectTwo;
