import React from 'react';
import '../styles/About.css';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Luqmaan_cv from '../assets/documents/Luqmaan_CV.pdf';
import { Link } from 'react-router-dom';

const About = () => {
  const aboutPageLinks = [
    { text: 'HOME', to: '/' },
    { text: 'ABOUT', to: '/about' }, // You can omit this if you don't want the About link to appear active
    { text: 'PROJECTS', to: '/all_projects' },
    { text: 'GALLERY', to: '/#gallery' },
    { text: 'CONTACT', to: '/#contact' }
  ];

  return (
    <section className='about_sec'>
      {/* Reuse the Header component with specific site title and links */}
      <Header links={aboutPageLinks}/>

      <div className='about_text_container'>
        
        <h1>About Me</h1>

        <p className='p_about'>
          As a recent graduate with a Master's degree, I have a passion for architecture 
          has been a driving force throughout my academic journey, and I am eager to apply 
          my skills and knowledge to contribute to the success of your company.
        </p>

        <p className='p_about'>
          During my studies, I developed a strong foundation in design principles. 
          I have experience using a range of architectural software, including AutoCAD, 
          SketchUp, Revit and Twinmotion.I am comfortable working in both 2D and 3D environments.  
          I have a particular interest in 3D modeling and rendering.
        </p>

        <p className='p_about'>
          I am thrilled about the opportunity to work with experienced professionals. 
          I believe that my willingness to learn and contribute, as well as my proficiency 
          in using industry-standard software, will enable me to make a valuable contribution to your firm.
        </p>

        <p className='p_about'>
          Thank you for considering my application.
          I look forward to discussing my qualifications further.
        </p>

        <Link to={Luqmaan_cv} download='Luqmaan CV' target='_blank' rel="noopener noreferrer">
          <button type="button" className="cv_download_button">Download CV</button>
        </Link>

      </div>

      <div className='education_container'> 

          <h2 className='education_h'>Education</h2>


          <div className='education_info'>
            
            <div className='education_year'>
              <h3>2022 - 2023</h3>
              <h2>NEAR EAST UNIVERSITY</h2>

            </div>
            <div className='education_study'>
              <h2>Master of Architecture</h2>
              <ul>
                <li>Published an article titled “Analyzing User 
                  Satisfaction and Perception for the Sustainable 
                  Development of Mama Ngina Waterfront in Kenya”.</li>

                <li>Attained a CGPA of 4.00 out of 4.00</li>
              </ul>
            </div>
          </div>

          <div className='education_info'>
            
            <div className='education_year'>
              <h3>2017-2021</h3>
              <h2>NEAR EAST UNIVERSITY</h2>

            </div>
            <div className='education_study'>
              <h2>Bachelor of Architecture</h2>
              <ul>
                <li>Attained a CGPA of 3.45 out of 4.00 </li>
                <li>Participated in multiple workshops, 
                  chief among them, "The Soul of Nicosia Streets."</li>
              </ul>
            </div>
          </div>

          <div className='education_info'>
            
            <div className='education_year'>
              <h3>2013-2016</h3>
              <h2>AGA KHAN HIGH SCHOOL</h2>

            </div>
            <div className='education_study'>
              <h2>KCSE</h2>
              <ul>
                <li>Kenya Certificate of Secondary Education.</li>
                <li>Attained the grade B - (Minus).</li>
              </ul>
            </div>
          </div>

       </div>

       
    
      <Footer/>
    </section>
  );
};

export default About;
