// Footer.js
import React from 'react';
import { MdLocationOn, MdEmail ,MdCall,MdWhatsapp ,MdFacebook  } from "react-icons/md";
import { FaXTwitter } from "react-icons/fa6";
import '../styles/Footer.css';


const Footer = () => {

  return (
    <footer>
      <div className='footer_info_container'>

        <ul className='footer_info'>
          <li><h2>Luqmaan Albakry</h2></li>
          
          <li>
            <p>As an architect and interior designer, I'm committed 
              to seamlessly blending form and function, crafting spaces 
              where every detail tells a story of creativity and innovation.</p>
          </li>
          <li className='social_apps'>
            <MdWhatsapp className='social_icon'/> 
            <MdFacebook className='social_icon'/> 
            <FaXTwitter className='social_icon'/> 
            
          </li>
          

        </ul>

        <ul className='footer_info'>
          <li><h2>Get In Touch :</h2></li>
          
          <li className='footer_contact_li'>
            <MdLocationOn className='footer_icon'/>
            <div class="footer-location-info">
              <span>Al Khuwayr South</span><br></br>
              <span>Muscat, Oman</span><br></br><br></br>
            </div>
          </li>

          <li className='footer_contact_li'>
            <MdCall className='footer_icon'/>
            <div class="footer-contact-info">
              <span>+968 9434 3038</span><br></br><br></br>
            </div>
          </li>

          <li className='footer_contact_li'>
            <MdEmail className='footer_icon'/>
            <div class="footer-contact-info">
              <span>luquefihri@gmail.com</span><br></br><br></br>
            </div>
          </li>

        </ul>


        <ul className='footer_info'>
          <li><h2>Quick Links</h2></li>
          <li><a href="/all_projects" className='footer_link_projects'>View Projects</a></li>
        </ul>

        
      </div>

      <p className='footer_txt'>Copyright &copy; 2024 Luqmaan Fihri Abdallah.</p>
    </footer>
  );
};

export default Footer;
