import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../styles/AllProjects.css';

import imgA1 from '../assets/images/project_one/imgA1.webp';
import imgB1 from '../assets/images/project_two/imgB1.webp';
import imgC1 from '../assets/images/project_three/imgC1.webp';
import imgD1 from '../assets/images/project_four/imgD1.webp';
import imgE15 from '../assets/images/project_five/imgE15.webp';
import imgF1 from '../assets/images/project_six/imgF1.webp';


const AllProjects = () => {

  // Define site title and links specific to the AllProjects page
  const allProjectsPageLinks = [
    { text: 'HOME', to: '/' },
    { text: 'ABOUT', to: '/about' },
    { text: 'PROJECTS', to: '/all_projects' }, // You can omit this if you don't want the Projects link to appear active
    { text: 'GALLERY', to: '/#gallery' },
    { text: 'CONTACT', to: '/#contact' }
  ];


  return (
    <section className='allprojects_sec'>
      {/* Reuse the Header component with specific site title and links */}
      <Header links={allProjectsPageLinks} />
      
      <h1 className='all_projects_h'>PROJECTS</h1>
      <div className='all_projects_images'>

        <div className="project-container">
          <a href='/project_one'>
            <img className='project_image' src={imgA1} alt='sample project'/> 
            <p className="image-text">Project One</p> 
          </a> 
        </div>

        <div className="project-container">
          <a href='/project_two'>
            <img className='project_image' src={imgB1} alt='sample project'/> 
            <p className="image-text">Project Two</p> 
          </a> 
        </div>

        <div className="project-container">
          <a href='/project_three'>
            <img className='project_image' src={imgC1} alt='sample project'/> 
            <p className="image-text">Project Three</p> 
          </a> 
        </div>

        <div className="project-container">
          <a href='/project_four'>
            <img className='project_image' src={imgD1} alt='sample project'/> 
            <p className="image-text">Project Four</p> 
          </a> 
        </div>

        <div className="project-container">
          <a href='/project_five'>
            <img className='project_image' src={imgE15} alt='sample project'/> 
            <p className="image-text">Project Five</p> 
          </a> 
        </div>

        <div className="project-container">
          <a href='/project_six'>
            <img className='project_image' src={imgF1} alt='sample project'/> 
            <p className="image-text">Project Six</p> 
          </a> 
        </div>

      </div>

      <Footer />
    </section>
  );
};

export default AllProjects;
